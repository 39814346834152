import React, { useState } from 'react';
import { Link } from 'gatsby';
import Nav from './Nav';

interface Props {
  fullMenu?: string;
}

const SideBar: React.FC<Props> = ({ fullMenu }) => {
  const [headerOpen, toggleHeader] = useState(false);
  return (
    <header id="header" className={`${fullMenu ? '' : 'alt'}`}>
      <h1>
        <Link to="/">SmartPantry</Link>
      </h1>
      <div className={`${headerOpen ? 'is-menu-visible' : ' '}`}>
        <Nav onMenuToggle={() => toggleHeader(!headerOpen)} />
      </div>
    </header>
  );
};

export default SideBar;
